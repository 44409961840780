@charset "utf-8";
//============================================================
//    基本設定
//============================================================
.mb-l {
	margin-bottom: 30px;
  }
  .mb-m {
	margin-bottom: 15px;
  }
  .mb-s {
	margin-bottom: 10px;
  }
	.sp-tac{
		text-align: center;
	}
  .header {
	padding: 10px;
	position: fixed;
	top: 0;
	left: 0px;
	width: 100%;
	height: auto;
	z-index: 10;
	background: #fff;
	justify-content: space-between;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	z-index: 15;
	opacity: 0;
	transition: opacity $easeInSine 300ms 1000ms;
	&.show {
	  opacity: 1;
	}
	.logo {
	//   padding-bottom: 15px;
	  width: 100px;
	  a {
		img {
		  width: 100%;
		  height: auto;
		}
	  }
	}

	.gnav {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow-y: scroll;
		padding: 15px;
		transition: transform $easeInSine 500ms;
		transform: translateY(-100vh);
		background: #fff;
	  ul {
		li {
		  a {
			display: block;
			padding: 10px 0;
			text-align: center;
			color: $normalC;
			&.contact {
			  background: $mainC;
			  color: #fff;
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  .ico {
				width: 20px;
				margin-right: 10px;
				display: inline-block;
				img {
				  width: 100%;
				}
			  }
			}
		  }
		}
	  }
	  .tel-box {
		background: $colorB;
		padding: 10px 5px;
		color: #fff;
		text-align: center;
		.title {
		  font-size: 12px;
		  margin-bottom: 5px;
		}
		.tel {
			color: #fff;
		  .ico {
			width: 20px;
			margin-right: 10px;
			display: inline-block;
			img {
			  width: 100%;
			}
		  }
		}
	  }
	}
  }
  .menu-trigger {
	position: fixed;
	right: 10px;
	top: 10px;
	width: 60px;
	height: 60px;
	padding: 10px;
	box-sizing: border-box;
	z-index: 3;
	&.show {
	  opacity: 1;
	}
	span {
	  width: 40px;
	  height: 1px;
	  background: $normalC;
	  position: relative;
	  display: block;
	  transition: transform $easeInSine 500ms;

	  &:before {
		content: "";
		width: 100%;
		height: 1px;
		background: $normalC;
		position: absolute;
		top: 15px;
		transform: rotate(0);
		transition: transform $easeInSine 500ms;

	  }
	  &:after {
		content: "";
		width: 100%;
		height: 1px;
		background: $normalC;
		position: absolute;
		top: 30px;
		transform: rotate(0);
		transition: transform $easeInSine 500ms;

	  }
	}
  }
  .menu-show{
	  
	  .header{
		  .gnav{
			transform: translateY(0vh);
		  }
	  }
	  .menu-trigger{
		span{
			background: transparent;
			&:before{
			  transform: rotate(45deg);
				background: $normalC;

			}
			&:after{
			  transform: rotate(-45deg);
				background: $normalC;
				top:15px;
			}
		}
	}
  }
  
body {
	// border-top: 10px solid #000;
	background: $normalC;
	line-height: 1.8;
	background-color: #fff;
	color: $normalC;
	font-size: 14px;
	font-family: $fm;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	a {
	  color: $mainC;
	  transition: $ease 300ms;
	  text-decoration: none;
	  &:hover {
	  }
	}
  }
  .first-wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 80px;
	.slide-wrap {
	  position: relative;
	  padding: 0;
	  box-sizing: border-box;
	  z-index: 1;
		background: rgb(207, 172, 172);
		.top-slider{
			.swiper-container{
				width: 100%!important;
				padding: 0!important;
				.swiper-wrapper{
					.swiper-slide{
						width: 100%!important;
						img{
							width: 100%!important;
							height: auto;
						}
					}
				}
			}
		}

	}
	.catch-box {
	  position: absolute;
	  left: 0px;
	  top: 0px;
	  // font-family: $fm;
	  z-index: 12;
	  height: 100%;
		width: 100%;
	  color: $mainC;
	  line-height: 1.9;
	  letter-spacing: 0.03em;
		text-align: center;
	  color: #fff;
	  font-family: $fm;
		text-align: center;
	  h1 {
		font-size: 24px;
		margin-bottom: 15px;
		font-weight: normal;
		transition: all $easeInSine 1000ms 800ms;
		margin-top: 100px;
		opacity: 0;
		img{
		  width: 100px;
		  height: auto;
		}
		&.show {
		  opacity: 1;
		}
	  }
	  h2 {
		font-size: 18px;
		transition: all $easeInSine 500ms 1800ms;
		opacity: 0;
		font-weight: normal;
		text-shadow:0px 0px 30px rgba(0,0,0,0.8);
  
		span {
		  // background: #fff;
		  padding: 15px;
		  position: relative;
		  
		}
		&.show {
		  opacity: 1;
		  transform: translateY(0px);
		}
	  }
	}
	.banner-wrap{
	  // position: absolute;
	  // bottom: 0px;
	  // left: 0px;
	  // width: 100%;
	  z-index: 12;
		position: relative;
	  a{
		display: block;
		text-align: center;
		transition: all 300ms;
		background: url(../img/bg-campaign.jpg) no-repeat center center;
		background-size: cover;
		color: #fff;
		padding:15px;
		position: relative;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
		border: 1px solid #fff;
		&:before{
		  content: '';
		  background: rgba(0,0,0,0.5);
		  position: absolute;
		  left: 0;
		  top: 0;
		  width: 100%;
		  height: 100%;
		}
		&:hover{
		  opacity: 0.8;
		}
		.detail{
		  position: relative;
		  z-index: 1;
		  .bg{
			display: none;
		  }
		  .sub-title{
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 10px;
		  }
		  .title{
			font-weight: bold;
			font-size: 24px;
			margin-bottom: 10px;
		  }
		  .text{
			span{
			  display: inline-block;
			  width: 20px;
			  height: 20px;
			  position: relative;
			  margin-left: 15px;
			  &:before{
				content: '';
				background: #fff;
				width: 1px;
				height: 100%;
				position: absolute;
				left:50%;
				top: 0;
			  }
			  &:after{
				content: '';
				border-right: 1px solid #fff;
				border-bottom: 1px solid #fff;
				position: absolute;
				left:4px;
				bottom: 0;
				transform:rotate(45deg);
				width: 14px;
				height: 14px;
				
			  }
			}
		  }
		}
		
  
	  }
	}
  }
  .top-contents {
	position: relative;
	box-sizing: border-box;
	.top-common {
	  padding: 30px 0;
	  box-sizing: border-box;
	  &.bg-y{
		background: #fff7eb;
	  }
	  &.bg-p{
		background: #f4ebff;
	  }
	  &.bg-w{
		background: #fff;
	  }
	  &.bg-gray{
		background: #e7e7e7;
	  }
	  &.bg-cam{
		background: url(../img/bg-campaign.jpg) no-repeat center bottom;
		background-size: cover;
		overflow: hidden;
		position: relative;
		&:before{
		  content: '';
		  left: 0;
		  top: 0;
		  width: 100%;
		  height: 100%;
		  background: rgba(0,0,0,0.5);
		  position: absolute;
		}
		.cmpaign-outer{
		  position: relative;
		  z-index: 1;
		}
	  }
	  .head-line {
		color: $normalC;
		margin-bottom: 30px;
		text-align: center;
		&.loaded {
		  h1 {
			opacity: 1;
			span {
			  
			}
		  }
		  h2 {
			opacity: 1;
			span {
			  
			}
		  }
		}
		h1 {
		  font-family: $fm;
		  font-size: 24px;
		  letter-spacing: 0.1em;
		  // margin-bottom: 15px;
		  font-weight: normal;
		  line-height: 24px;
		  opacity: 0;
		  transition: opacity $easeInSine 500ms 300ms;
  
		  span {
			padding: 5px;
			border-bottom: 1px solid $linkC;
			display: inline-block;
			position: relative;
		  
		  }
		}
		h2 {
		  font-family: $fg;
		  font-size: 12px;
		  transition: opacity $easeInSine 500ms 500ms;
		  opacity: 0;
		  span {
			background: transparent;
			padding: 5px 20px;
			display: inline-block;
			position: relative;
  
		   
		  }
		}
		&:after {
		  // content: "";
		  // width: 180px;
		  // height: 1px;
		  // background: $mainC;
		  // display: block;
		  // margin-top: 15px;
		}
	  }
	  &.concept-wrap {
		// background: $colorY;
		.concept-inner {
		 
		 
		  margin-bottom: 15px;
		  .photo{
			img{
			  max-width: 100%;
			  height: auto;
			}
		  }
		  .detail{
			font-family: $fm;
			font-size: 18px;
			strong{
			  // display: inline-block;
			  padding: 0px 5px 2px;
			  border-bottom: 1px solid #000;
			}
		  }
	   
		 
		}
	  }
	  .inner {
		margin: 0 auto;
		width: 96%;
  
  
	  }
	}
  }
  // TOP & SUB COMMON
  
  .news-list {
	li {
	  border-bottom: 1px solid $mainC;
	  a {
		display: flex;
		padding: 15px;
		justify-content: space-between;
		.date {
		  background: $colorY;
		  width: 80px;
		  padding: 5px;
		  color: #fff;
		  font-size: 12px;
		  text-align: center;
		}
		
		.title {
		  color: $normalC;
		  width: calc(100% - 90px);
		}
	  }
	}
  }

  .end-movie-area{
	.title{
	  font-weight: bold;
	  font-size: 18px;
	  margin-bottom: 15px;
	}
	.sub-title{
	  font-size: 16px;
	  margin-bottom: 30px;
	}
	.photo{
	  text-align: center;
	  margin-bottom: 30px;
	  img{
		max-width: 100%;
		height: auto;
	  }
	}
	.detail{
	  margin-bottom: 50px;
	}
  }
  .movie-wrap{
	.movie{
	  margin-bottom: 50px;
	  text-align: center;
	  .title{
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 30px;
	  }
	  iframe {
		width: 100%;
		height: 240px;
	  }
	}
	
	.explain{
	  background: rgb(251, 255, 232);
	  padding: 30px;
	  font-size: 16px;
	  strong{
		font-weight: bold;
		display: inline-block;
		border-bottom: 1px solid $normalC;
	  }
	}
  }
  .campaign-wrap{
	text-align: center;
	color: #fff;
	.sub-title{
	  font-size: 22px;
	  font-weight: bold;
	  margin-bottom: 15px;
	  position: relative;
	  .bg{
		// position: absolute;
		// left: 0;
		// top: -60px;
		// width: 100%;
		// height: 180px;
		// background-repeat: no-repeat;
		// background-position: right top;
		// background-size: 280px auto;
		display: none;
	  }
	}
  
	.title{
	  font-weight: bold;
	  font-size: 26px;
	  margin-bottom: 30px;
	}
  
	.ex{
	  font-size: 16px;
	  margin-bottom: 30px;
	}
  }
  .btn-wrap{
	
	.btn {
	  display: flex;
	  padding: 20px;
	  box-sizing: border-box;
	  width:100%;
	  align-items: center;
	  justify-content: center;
	  font-size: 22px;
	  color: #fff;
	  font-weight: bold;
	  margin-bottom: 30px;
	  i{
		display: inline-block;
		width: 30px;
		margin-right: 10px;
		img{
		  width: 100%;
		  height: auto;
		}
	  }
	  &.campaign{
		background: $linkC;
	  }
	  &.leafret{
		background: $mainC;
	  }
	}
  }
  .car-list{
	display: flex;
	flex-wrap: wrap;
	li{
	  width: calc(100% / 2);
	  padding: 0 20px;
	  margin-bottom: 40px;
	  a{
		.photo{
		  text-align: center;
		  margin-bottom: 15px;
		  img{
			max-width: 100%;
			height: auto;
		  }
		}
		.title{
		  font-size: 18px;
		}
	  }
	}
  }
  .detail-table{
	width: 100%;
	margin-bottom: 30px;
	tr{
	  border-bottom: 1px solid #000;
	  th{
		background: #f7f7f7;
		padding: 15px;
	  }
	  td{
		padding: 15px;
	  }
	}
  }
  .contents {
	.header-box {
	  background-position: center center;
	  background-repeat: no-repeat;
	  background-size: cover;
	  padding: 120px 0;
	  font-family: $fm;
	  letter-spacing: 0.2em;
	  // margin-bottom: 70px;
	  text-align: right;    
	  .title {
		font-size: 24px;
		font-weight: normal;
		margin-bottom: 15px;
		color: #fff;
		letter-spacing: 0.2em;
		text-shadow:0px 0px 5px rgba(0,0,0,0.8);
  
		span {
		  display: inline-block;
		  padding: 0px 30px;
		  // background: rgba(255, 255, 255, 0.8);
		  // border-bottom: 1px solid #fff;
		  &:after {
			content: "";
			display: block;
			width: calc(100% + 60px);
			margin-left: -30px;
			height: 1px;
			background: #fff;
			transform: scaleX(0);
			transform-origin: right center;
			transition: transform $easeInSine 500ms 300ms;
		  }
		}
	  }
	  .sub-title {
		font-size: 12px;
		color: #fff;
		transition: opacity $easeInSine 500ms 300ms;
		opacity: 0;
		text-shadow:0px 0px 5px rgba(0,0,0,0.8);
		padding-right: 40px;
	  }
	  &.loaded {
		.title {
		  span {
			&:after {
			  transform: scaleX(1);
			}
		  }
		}
		.sub-title {
		  opacity: 1;
		}
	  }
	}
	.head-line {
	  color: $normalC;
	  margin-bottom: 50px;
	  text-align: center;
	  &.loaded {
		h1 {
		  span {
			
		  }
		}
		h2 {
		  span {
			
		  }
		}
	  }
	  h1 {
		font-family: $fm;
		font-size: 28px;
		letter-spacing: 0.2em;
		// margin-bottom: 15px;
		font-weight: normal;
		line-height: 28px;
		span {
		  padding: 5px 50px 10px;
		  border-bottom: 1px solid $linkC;
		  display: inline-block;
		  position: relative;
		  
		}
	  }
	  h2 {
		font-family: $fg;
		font-size: 12px;
  
		span {
		  background: transparent;
		  padding: 5px 20px;
		  display: inline-block;
		  position: relative;
		  transition: background-color $easeInSine 500ms 300ms;
  
		}
	  }
	  &:after {
		// content: "";
		// width: 180px;
		// height: 1px;
		// background: $mainC;
		// display: block;
		// margin-top: 15px;
	  }
	  .cat{
		background: $mainC;
		padding: 5px 10px;
		font-size: 14px;
		color: #fff;
		display: inline-block;
		position: absolute;
		right:70px;
	  }
	  .tag{
		display: inline-block;
		margin-left: 20px;
		a{
		  display: inline-block;
		  margin-right: 10px;
		  &:before{
			content: '#';
		  }
		}
	  }
	}
	.sub-contents {
	  padding: 30px 0;
	  &.no-title {
	  }
	  &.bg-y {
		background: #fff6d8;
	  }
	  &.bg-f {
		background: #fff;
	  }
	  .inner {
		margin: 0 auto;
		width: 96%;
		
		.wb-list{
		  padding: 30px;
		  background: #fff;
		  box-sizing: border-box;
				dt{
			padding-top: 30px;
			.title{
			  font-weight: bold;
			  font-size: 22px;
			  margin-bottom: 30px;
			  color: $colorOR;
			}
		  }
				dd{
			padding-bottom: 30px;
			margin-bottom: 30px;
					.top-text{
			  font-weight: bold;
			  font-size: 18px;
			  margin-bottom: 30px;
			}
			.img{
			  text-align: center;
			  margin-bottom: 30px;
			  img{
				max-width: 100%;
				height: auto;
			  }
			}
					.sub-text{
			  font-size: 16px;
			  margin-bottom: 30px;
			}
			border-bottom: 1px solid $mainC;
		  }
		}
		.concept-inner {
		  text-align: center;
		  font-family: $fm;
		  font-size: 18px;
		  .title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 15px;
		  }
		  &.philosophy{
			.title{
			  text-align: center;
			}
			text-align: left;
		  }
		}
  
		.category-wrap {
		  margin-bottom: 50px;
		  .category-list {
			text-align: center;
			li {
			  text-align: center;
			  padding: 15px;
			  display: inline-block;
			  a {
				display: inline-block;
				padding: 15px 30px;
				background: $colorB;
				color: #fff;
				&:hover {
				  background: $colorY;
				}
			  }
			}
		  }
		}
		.company-table {
		  width: 100%;
		  margin-bottom: 30px;
		  tr {
			border-bottom: 1px solid #eee;
			th {
			  padding: 15px;
			  text-align: center;
			}
			td {
			  padding: 15px;
			}
		  }
		}
		.map-wrap {
		  iframe {
			width: 100%;
		  }
		}
		.image-text-wrap{
		  display: flex;
		  margin-bottom: 50px;
  
		  &.right{
			flex-direction: row-reverse;
			.image{}
			.text-wrap{
			  transform: translate3d(50px, 50px, 0);
			}
		  }
		  .image{
			width: 600px;
			img{}
		  }
		  .text-wrap{
			width: calc(100% - 600px);
			background: rgba(255,255,255, 0.6);
			padding:30px;
			transform: translate3d(-50px, 50px, 0);
			.title{
			  font-size: 18px;
			  font-weight: bold;
			  margin-bottom: 15px;
			  color: $mainC;
			  width: 100%;
			  // font-family: $fm;
			}
			.text{
			  width: 100%;
			  // font-family: $fm;
			  
			}
		  }
		}
		.common-table{
		  width: 100%;
		  margin-bottom: 30px;
		  thead{
			tr{
			  th{}
			  td{}
			}
		  }
		  tr{
			border-bottom: 1px solid #eee;
			th,td{
			  padding: 15px;
			}
			th{
			  background: $mainC;
			  color: #fff;
			}
			td{
			  background: #fff;
			}
		  }
		}
		h3.sub-title{
		  color: $mainC;
		  font-size: 18px;
		  font-weight: bold;
		  border-bottom: 1px solid $mainC;
		  margin-bottom: 30px;
		  span{
			border-bottom: 3px solid $mainC;
			box-sizing: border-box;
			display: inline-block;
			padding: 0 15px;
			margin-bottom: -2px;
		  }
		}
  
  
	  }
	}
  
  }
  .footer {
	position: relative;
	padding: 30px 0;
	background: $normalC;
	color: #fff;
	a{
	  color: #fff;
	}
	.page-top {
	  display: none;
	  a {
		span {
		}
	  }
	}
	.footer-inner {
		width:96%;
	  margin: 0 auto;
	  .footer-banner{
		margin-bottom: 30px;

	  }
	  .footer-menu {
		ul{
		  text-align: center;
		  li {
			position: relative;
			display: inline-block;
			margin-bottom: 15px;
			text-align: center;
			a {
			  padding: 5px 15px;
			  font-size: 16px;
			  vertical-align: middle;
			  &.contact {
				.ico {
				  display: none;
				  
				}
			  }
			  i{
				display: inline-block;
				width: 40px;
				padding-right: 5px;
				img{
				  width: 100%;
				}
			  }
			}
		   
		  }
		}
		
	  }
	  .company-area{
		margin-bottom: 15px;
	
		.address{
		  margin-bottom: 15px;
		  font-style: normal;
		  .title{
			font-weight: bold;
			margin-bottom: 10px;
		  }
		  .detail{}
		}
		.company-link{
		  a{
			display: inline-block;
			padding:5px 15px;
		  }
		}
	  }
	}
	.copy {
	  padding-top: 30px;
	  text-align: center;
	  p {
	  }
	}
  }
  .sub-page {
	section {
	  .inner {
		// min-width: 1000px;
		// max-width: 1400px;
	  }
	}
  }
  
  .more {
	display: block;
	width: 300px;
	height: 60px;
	background: $mainC;
	color: #fff;
	font-size: 18px;
	line-height: 60px;
	text-align: center;
	margin: 50px auto;
	&:hover {
	  background: $colorB;
	  color: #fff;
	}
	&.bg-f {
	  color: $mainC;
	  background: #fff;
	}
  }
  
  /* ----------------------------- */
  /* ------ input,textarea  ------ */
  /* ----------------------------- */
  form{
	.btn-wrap{
	  
	  margin:15px auto;
	  .btn{
		// margin: 0 20px;
	  }
	}
	.form-group {
	  border-top: 1px solid #eee;
	  padding: 30px 0;
	  margin-bottom: 50px;
	  border-bottom: 1px solid #eee;
	  width: 100%;
	//   display: flex;
	//   align-items: start;
	//   flex-wrap: wrap;
	  .error{
		color: #ff0000;
		display: block;
		width: 100%;
	  }
	  label{
		
	  }
	  .input-wrap{
		input[type='text'],
		input[type='email']{
		  width: 100%;
		  padding: 5px;
		  border-radius: 5px;
		  border: 1px solid #ccc;
		  appearance: none;
		  -webkit-appearance: none;
		  -moz-appearance: none;
		}
		input[type='text'].mini{
		  min-width: 100px;
		  width: auto;
		}
	  
		textarea{
		  width: 100%;
		  height: 300px;
		  padding: 5px;
		  border-radius: 5px;
		  border: 1px solid #ccc;
		  appearance: none;
		  -webkit-appearance: none;
		  -moz-appearance: none;
		}
	  }
	  
	}
	input[type='button']{
	  border: none;
	  display: block;
	  width: 200px;
	  padding: 10px;
	  border-radius: 5px;
	  background-color: $mainC;
	  color: #fff;
	  font-weight: bold;
	  font-size: 18px;
	  appearance: none;
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  cursor: pointer;
	  margin: 0 auto;
	}
	
  } 
  
  .modal-wrap{
	position: fixed;
	background: rgba(0,0,0,0.6);
	padding: 30px 15px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	transform:translateY(-100%);
	transition: all 300ms;
	&.show{
	  transform:translateY(0%);
	}
	.modal-inner{
	  background: #fff;
	  padding: 30px;
	  position: relative;
	  .close{
		position: absolute;
		width: 20px;
		height: 20px;
		right: 20px;
		top: 20px;
		&:before,
		&:after{
		  content: '';
		  width: 20px;
		  height: 1px;
		  background: #000;
		  display: block;
		}
		&:before{
		  transform:rotate(45deg);
		}
		&:after{
		  transform:rotate(-45deg);
		}
	  }
	  .detail{
		.title{}
		p{}
	  }
	}
  }
  