@charset "utf-8";
@import "setting";
@import "components/reset";
@import "addon/swiper";
@import "addon/magnific-popup";


/*----------------------
  common
----------------------*/

a {
  color: $mainC;
  text-decoration: underline;
  &:hover {
    color: $mainC;
    text-decoration: none;
  }
}

.list-mark {
  text-indent: -1em;
  padding: 0 0 0 1em;
  overflow: hidden;
}

.btn-wrap{
  .btn{
    display: block;
    width: 300px;
    padding: 15px 50px;
    border: none;
    outline: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    &.primary{
      background: $linkC;

    }
    &.secondary{
      background: #ccc;
    }
  }

}
form .back{
  display: inline-block;
    padding: 15px 50px;
    box-sizing: border-box;
    background: #ccc;
    font-size: 16px;
}
form .confirm{
  display: inline-block;
  padding: 15px 50px;
  box-sizing: border-box;
  background: #333333;
  font-size: 16px;
  color: #fff;
  vertical-align: top;
  font-weight: bold;
}



/*----------------------
  common
----------------------*/

@media print, screen and (min-width: $w-sp + 1) {
  @import "pc";
  .sp{
    display: none!important;
  }
}

/*end query*/
@media screen and (max-width: $w-sp) {
  @import "sp";
  .pc{
    display: none!important;
  }
}


.text-xl3{
  font-size: 1.6rem;

}
.text-xl2{
  font-size: 1.4rem;

}
.text-xl{
  font-size: 1.2rem;

}
.text-m{ 
  font-size: 1rem;
}
.text-s{
  font-size: 0.8rem;
}
.text-xs{
  font-size: 0.7rem;
}
.font-bold{
  font-weight: bold;
}